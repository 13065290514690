
export const flashLight = [
    { code: 1, productName: '4’’ Lakshmi', content: '5 Pcs', actualRate: '₹ 147', price: 22 },
    { code: 2, productName: '3 ½” Lakshmi', content: '5 Pcs', actualRate: '₹ 105', price: 15.75 },
    { code: 3, productName: '2 3/4” Kuruvi', content: '5 Pcs', actualRate: '₹ 50', price: 7.50 },
    { code: 4, productName: '4’’ Gold Lakshmi', content: '5 Pcs', actualRate: '₹ 234', price: 35 },
    { code: 5, productName: '4” Hanuman', content: '5 Pcs', actualRate: '₹ 270', price: 40 },
    { code: 6, productName: 'Double Sound', content: '5 Pcs', actualRate: '₹ 234', price: 35 },
];

export const bijili = [
    { code: 7, productName: 'Red Bijili', content: '1 Bag', actualRate: '₹ 214', price: 32 },
    { code: 8, productName: 'Stripped Bijili', content: '1 Bag', actualRate: '₹ 234', price: 35 },
];

export const pencil = [
    // { code: 22, productName: '7” Pencil', content: '10 Pcs', actualRate: '₹ 147', price: 22 },
    { code: 9, productName: '10” Pencil', content: '10 Pcs', actualRate: '₹ 294', price: 44 },
    { code: 10, productName: '12” Pencil', content: '10 Pcs', actualRate: '₹ 468', price: 70 },
    { code: 11, productName: 'Rainbow Pencil (Tri Color)', content: '5 Pcs', actualRate: '₹ 834', price: 125 },
];

export const mega = [
    { code: 12, productName: 'Power Rangers', content: '2 Pcs', actualRate: '₹ 1250', price: 187 },
];

export const chakra = [
    { code: 13, productName: 'Ground Chakkar Big (10 Pcs)', content: '10 Pcs', actualRate: '₹ 200', price: 30 },
    { code: 14, productName: 'Ground Chakkar Special', content: '10 Pcs', actualRate: '₹ 460', price: 69 },
    { code: 15, productName: 'Ground Chakkar Deluxe', content: '10 Pcs', actualRate: '₹ 634', price: 95 },
    { code: 16, productName: 'Whistling Wheel', content: '5 Pcs', actualRate: '₹ 800', price: 120 },
    { code: 17, productName: 'Super Deluxe Plastic Chakkar', content: '10 Pcs', actualRate: '₹ 1100', price: 165 },
    { code: 18, productName: 'Whistling Wheel', content: '5 Pcs', actualRate: '₹ 800', price: 120 },
    { code: 19, productName: 'Ground Chakkar Big (25 Pcs)', content: '10 Pcs', actualRate: '₹ 434', price: 65 },
];

export const twinkling = [
    { code: 20, productName: '1 ½” Twinkling Star', content: '10 Pcs', actualRate: '₹ 140', price: 21 },
    { code: 21, productName: '4” Twinkling Star', content: '10 Pcs', actualRate: '₹ 454', price: 68 },
];

export const flowerPots = [
    { code: 22, productName: 'Flower Pots Small', content: '10 Pcs', actualRate: '₹ 270', price: 40 },
    { code: 23, productName: 'Flower Pots Big', content: '10 Pcs', actualRate: '₹ 367', price: 55 },
    { code: 24, productName: 'Flower Pots Special', content: '10 Pcs', actualRate: '₹ 500', price: 75 },
    { code: 25, productName: 'Flower Pots Asoka', content: '10 Pcs', actualRate: '₹ 668', price: 100 },
    // { code: 39, productName: 'Flower Pots Giant', content: '10 Pcs', actualRate: '₹ 770', price: 115 },
    { code: 26, productName: 'Colour Koti', content: '10 Pcs', actualRate: '₹ 1135', price: 170 },
    { code: 27, productName: 'Colour Koti Deluxe', content: '10 Pcs', actualRate: '₹ 1834', price: 275 },
    { code: 28, productName: 'Flower Pots Deluxe', content: '5 Pcs', actualRate: '₹ 1000', price: 150 },
    { code: 29, productName: 'Flower Pots Super DIX', content: '2 Pcs', actualRate: '₹ 580', price: 87 },
    // { code: 44, productName: 'Multicolour Giant', content: '10 Pcs', actualRate: '₹ 1600', price: 240 },
];

export const colorFaountain = [
   
    
    { code: 30, productName: 'PopCorn (2 Pcs)', content: '2 Pcs', actualRate: '₹ 1340', price: 201 },
    { code: 31, productName: 'Tricolor Medium (UV) ', content: '5 Pcs', actualRate: '₹ 1135', price: 170 },
    { code: 32, productName: 'AV Tricolour Fountain', content: '5 Pcs', actualRate: '₹ 1500', price: 225 },
    { code: 33, productName: 'Photo Flash', content: '5 Pcs', actualRate: '₹ 535', price: 80 },
    { code: 34, productName: 'Magic Peacock 3 Phase', content: '1 Pcs', actualRate: '₹ 1168', price: 175 },
    { code: 35, productName: 'Bambara', content: '1 Pcs', actualRate: '₹ 834', price: 125 },
];

export const twoThirdFountain = [
    { code: 36, productName: 'Green Flash', content: '5 Pcs', actualRate: '₹ 668', price: 100 },
    { code: 37, productName: 'Red Flash', content: '5 Pcs', actualRate: '₹ 668', price: 100 },
    { code: 38, productName: 'Lolly Pop', content: '5 Pcs', actualRate: '₹ 668', price: 100 },
];
export const twoThirdFountainWedding = [
    { code: 39, productName: 'Wedding Red', content: '5 Pcs', actualRate: '₹ 868', price: 130 },
    { code: 40, productName: 'Wedding Green', content: '5 Pcs', actualRate: '₹ 868', price: 130 },
    { code: 41, productName: 'Wedding Yellow', content: '5 Pcs', actualRate: '₹ 868', price: 130 },
    { code: 42, productName: 'Wedding Red & Green', content: '5 Pcs', actualRate: '₹ 868', price: 130 }, 
    { code: 43, productName: 'Wedding Gold', content: '5 Pcs', actualRate: '₹ 868', price: 130 },
];
export const twoThirdCardSeries = [
    { code: 44, productName: 'The King Silver Star', content: '5 Pcs', actualRate: '₹ 600', price:90. },
    { code: 45, productName: 'The Queen Gold Star', content: '5 Pcs', actualRate: '₹ 600', price:90. },
    { code: 46, productName: 'The Joker Red & Green', content: '5 Pcs', actualRate: '₹ 600', price:90. },
   
];
export const twoThirdFountain1 = [
    { code: 39, productName: 'Green Flash', content: '5 Pcs', actualRate: '₹ 668', price: 100 },
    { code: 40, productName: 'Red Flash', content: '5 Pcs', actualRate: '₹ 668', price: 100 },
    { code: 41, productName: 'Lolly Pop', content: '5 Pcs', actualRate: '₹ 668', price: 100 },
    { code: 42, productName: 'Lolly Pop', content: '5 Pcs', actualRate: '₹ 668', price: 100 },
    { code: 43, productName: 'Lolly Pop', content: '5 Pcs', actualRate: '₹ 668', price: 100 },
];

export const twoThirdFountain2 = [
    { code: 44, productName: 'Green Flash', content: '5 Pcs', actualRate: '₹ 668', price: 100 },
    { code: 45, productName: 'Red Flash', content: '5 Pcs', actualRate: '₹ 668', price: 100 },
    { code: 46, productName: 'Lolly Pop', content: '5 Pcs', actualRate: '₹ 668', price: 100 },
];

export const fourSpecialFountain = [
    { code: 47, productName: 'Nesquick Red', content: '5 Pcs', actualRate: '₹ 1000', price: 150 },
    { code: 48, productName: 'Mr.Peabody Golden', content: '5 Pcs', actualRate: '₹ 1000', price: 150 },
    { code: 49, productName: 'Lorax Green', content: '5 Pcs', actualRate: '₹ 1000', price: 150 },
    { code: 50, productName: 'The Smurfs Red & Green', content: '5 Pcs', actualRate: '₹ 1000', price: 150 },
    { code: 51, productName: 'Strange World Silver', content: '5 Pcs', actualRate: '₹ 1000', price: 150 },
];

export const eightFountain = [
    { code: 52, productName: 'Cocktail', content: '1 Pcs', actualRate: '₹ 834', price: 125 },
    { code: 53, productName: 'Gold Rush', content: '1 Pcs', actualRate: '₹ 834', price: 125 },
    { code: 54, productName: 'Fly Bees Red', content: '1 Pcs', actualRate: '₹ 834', price: 125 },
    { code: 55, productName: 'Fly Bees Green', content: '1 Pcs', actualRate: '₹ 834', price: 125 },
];

export const fancyCrackers = [
    { code: 56, productName: 'Siren', content: '2 Pcs', actualRate: '₹ 1200', price: 180 },
    { code: 57, productName: '7 Shot-Colour Crackling', content: '5 Pcs', actualRate: '₹ 800', price: 120 },
    { code: 58, productName: 'Penta (5 Multicolour)', content: '5 Pcs', actualRate: '₹ 1000', price: 150 },
    { code: 59, productName: 'Chit Put', content: '10 Pcs', actualRate: '₹ 220', price: 33 },
    { code: 60, productName: 'Stone', content: '10 Pcs', actualRate: '₹ 100', price: 15 },
    { code: 61, productName: 'Serpant Egg Big (Sony)', content: '10 Pcs', actualRate: '₹ 334', price: 50 },
];

export const rockets = [
    { code: 62, productName: 'Rocket Bomb', content: '10 Pcs', actualRate: '₹ 347', price: 52 },
    { code: 63, productName: '2 Sound Rocket', content: '10 Pcs', actualRate: '₹ 740', price: 111 },
    { code: 64, productName: 'Lunik Rocket', content: '10 Pcs', actualRate: '₹ 740', price: 111 },
    { code: 65, productName: 'Whistling Rocket', content: '10 Pcs', actualRate: '₹ 1135', price: 170 },
];

export const bombCrackers = [
    { code: 66, productName: 'Mini Bullet', content: '10 Pcs', actualRate: '₹ 174', price: 26 },
    { code: 67, productName: 'Atom Bomb', content: '10 Pcs', actualRate: '₹ 267', price: 40 },
    { code: 68, productName: 'Hydro Bomb', content: '10 Pcs', actualRate: '₹ 367', price: 55 },
    { code: 69, productName: 'King of King (3 Ply)', content: '10 Pcs', actualRate: '₹ 467', price: 70 },
    { code: 70, productName: 'Classic Bomb (5 Ply)', content: '10 Pcs', actualRate: '₹ 647', price: 97 },
    { code: 71, productName: 'Jurassic Bomb (7 Ply)', content: '10 Pcs', actualRate: '₹ 1067', price: 160 },
]

export const avChottaFance = [
    { code: 72, productName: 'Jolly Enjoy Green', content: '1 Pcs', actualRate: '₹ 327', price: 49 },
    { code: 73, productName: 'Minnal Star White', content: '1 Pcs', actualRate: '₹ 327', price: 49 },
    { code: 74, productName: 'Golden Rang yellow', content: '1 Pcs', actualRate: '₹ 327', price: 49 },
    { code: 75, productName: 'Sun Flash Red', content: '1 Pcs', actualRate: '₹ 327', price: 49 },
    { code: 76, productName: 'Flash India Red & Green', content: '1 Pcs', actualRate: '₹ 327', price: 49 },
]

export const twoavChottaFance = [
    { code: 77, productName: 'Hoo Haa Red', content: '1 Pcs', actualRate: '₹ 868', price: 130 },
    { code: 78, productName: 'Wonder Thunder Green', content: '1 Pcs', actualRate: '₹ 868', price: 130 },
    { code: 79, productName: 'Sky Wonder Gold', content: '1 Pcs', actualRate: '₹ 868', price: 130 },
    { code: 80, productName: 'Spider Man Red & Green', content: '1 Pcs', actualRate: '₹ 868', price: 130 },
    { code: 81, productName: 'Jungle Cruise Silver', content: '1 Pcs', actualRate: '₹ 868', price: 130 },
]


export const twoavarielfancy = [
    { code: 82, productName: 'Captain Marvel Red', content: '3 Pcs', actualRate: '₹ 1734', price: 260 },
    { code: 83, productName: 'Civil War Silver', content: '3 Pcs', actualRate: '₹ 1734', price: 260 },
    { code: 84, productName: 'Wakanda Forever Green', content: '3 Pcs', actualRate: '₹ 1734', price: 260 },
    { code: 85, productName: 'Guardians of the Galaxy Gold', content: '3 Pcs', actualRate: '₹ 1734', price: 260 },
]


export const threeHalfavfancy = [
    { code: 86, productName: 'Arm Force (White)', content: '1 Pcs', actualRate: '₹ 1800', price: 270 },
    { code: 87, productName: 'Vision Perfect (Yellow)', content: '1 Pcs', actualRate: '₹ 1800', price: 270 },
    { code: 88, productName: 'Snippers Experts (Green)', content: '1 Pcs', actualRate: '₹ 1800', price: 270 },
    { code: 89, productName: 'Resident Gang (Red)', content: '1 Pcs', actualRate: '₹ 1800', price: 270 },
    { code: 90, productName: 'Red & Green', content: '1 Pcs', actualRate: '₹ 1800', price: 270 },
]

export const fourFouravfancy = [
    { code: 91, productName: 'Green Leaf', content: '2 Pcs', actualRate: '₹ 4000', price: 600 },
    { code: 92, productName: 'Red Leaf', content: '2 Pcs', actualRate: '₹ 4000', price: 600 },
    { code: 93, productName: 'Sky King', content: '2 Pcs', actualRate: '₹ 4000', price: 600 },
    { code: 94, productName: 'Golden Sky', content: '2 Pcs', actualRate: '₹ 4000', price: 600 },
    { code: 95, productName: 'Dragon Attack', content: '2 Pcs', actualRate: '₹ 4000', price: 600 },
    { code: 96, productName: 'Purple Rain', content: '2 Pcs', actualRate: '₹ 4000', price: 600 },
    { code: 97, productName: 'Fantastic White', content: '2 Pcs', actualRate: '₹ 4000', price: 600 },
]

export const fourHalfavfancy = [
    { code: 98, productName: 'Canada Nayagara', content: '1 Pcs', actualRate: '₹ 2334', price: 350 },
    { code: 99, productName: 'Los Angels Red', content: '1 Pcs', actualRate: '₹ 2334', price: 350 },
    { code: 100, productName: 'Paris Gold', content: '1 Pcs', actualRate: '₹ 2334', price: 350 },
    { code: 101, productName: 'Tokyo Red & Green', content: '1 Pcs', actualRate: '₹ 2334', price: 350 },
    { code: 102, productName: 'Londan Green', content: '1 Pcs', actualRate: '₹ 2334', price: 350 },
    { code: 103, productName: 'Melbourne Silver', content: '1 Pcs', actualRate: '₹ 2334', price: 350 },
]

export const fourAVDoubleEball = [
    { code: 104, productName: 'Fantastic Night (Red&Green)', content: '1 Pcs', actualRate: '₹ 2500', price: 375 },
    { code: 105, productName: 'Fantastic Night (Red&Crackling)', content: '1 Pcs', actualRate: '₹ 2500', price: 375 },
    { code: 106, productName: 'Fantastic Night (Yellow&Green)', content: '1 Pcs', actualRate: '₹ 2500', price: 375 },
]

export const fiveSriVijaiFancy = [
    { code: 107, productName: 'Big Splash', content: '2 Pcs', actualRate: '₹ 5334', price: 800 },
    { code: 108, productName: 'Mojo', content: '2 Pcs', actualRate: '₹ 5334', price: 800 },
    { code: 109, productName: 'Spot', content: '2 Pcs', actualRate: '₹ 5334', price: 800 },
    { code: 110, productName: 'Salsa', content: '2 Pcs', actualRate: '₹ 5334', price: 800 },
    { code: 111, productName: 'Pik Nik', content: '2 Pcs', actualRate: '₹ 5334', price: 800 },
]

export const speedMultiColorShot = [
    { code: 112, productName: '12 Shot Rider (Red&Green)', content: '1 Pcs', actualRate: '₹ 1000', price: 150 },
    { code: 113, productName: '25 Whistling Shot', content: '1 Pcs', actualRate: '₹ 4000', price: 600 },
    { code: 114, productName: '30 Multicolour Shot', content: '1 Pcs', actualRate: '₹ 2400', price: 360 },
    { code: 115, productName: '60 Multicolour Shot', content: '1 Pcs', actualRate: '₹ 4800', price: 720 },
    { code: 116, productName: '120 Multicolour Shot', content: '1 Pcs', actualRate: '₹ 10834', price: 1625 },
    { code: 117, productName: 'Good Vibes 240 Multicolor Mega', content: '10 Pcs', actualRate: '₹ 25414', price: 3812 },
]

export const sparklers = [
    { code: 118, productName: '9cm Electric Sparklers', content: '10 Pcs', actualRate: '₹ 74', price: 11 },
    { code: 119, productName: '9cm Colour Sparklers', content: '10 Pcs', actualRate: '₹ 87', price: 13 },
    { code: 120, productName: '9cm Green Sparklers', content: '10 Pcs', actualRate: '₹ 100', price: 15 },
    { code: 121, productName: '10cm Electric Sparklers', content: '10 Pcs', actualRate: '₹ 127', price: 19 },
    { code: 122, productName: '10cm Colour Sparklers', content: '10 Pcs', actualRate: '₹ 140', price: 21 },
    { code: 123, productName: '10cm Green Sparklers', content: '10 Pcs', actualRate: '₹ 167', price: 25 },
    { code: 124, productName: '12cm Electric Sparklers', content: '10 Pcs', actualRate: '₹ 187', price: 28 },
    { code: 125, productName: '12cm Colour Sparklers', content: '10 Pcs', actualRate: '₹ 200', price: 30 },
    { code: 126, productName: '12cm Green Sparklers', content: '10 Pcs', actualRate: '₹ 235', price: 35 },
    { code: 127, productName: '15cm Electric Sparklers', content: '10 Pcs', actualRate: '₹ 300', price: 45 },
    { code: 128, productName: '15cm Colour Sparklers', content: '10 Pcs', actualRate: '₹ 320', price: 48 },
    { code: 129, productName: '15cm Green Sparklers', content: '10 Pcs', actualRate: '₹ 354', price: 53 },
    { code: 130, productName: '15cm Red Sparklers', content: '10 Pcs', actualRate: '₹ 354', price: 53 },
    { code: 131, productName: '30cm Electric Sparklers', content: '5 Pcs', actualRate: '₹ 300', price: 45 },
    { code: 132, productName: '30cm Colour Sparklers', content: '5 Pcs', actualRate: '₹ 320', price: 48 },
    { code: 133, productName: '30cm Green Sparklers', content: '5 Pcs', actualRate: '₹ 354', price: 53 },
    { code: 134, productName: '30cm Red Sparklers', content: '5 Pcs', actualRate: '₹ 354', price: 53 },
    { code: 135, productName: 'Ramesh 50cm Electric', content: '5 Pcs', actualRate: '₹ 1268', price: 190 },
    { code: 136, productName: 'Ramesh 50cm Colour', content: '5 Pcs', actualRate: '₹ 1334', price: 200 },
    { code: 137, productName: 'Umberlla Sparklers', content: '5 Pcs', actualRate: '₹ 1200', price: 180 },
]

export const colorMatches = [
    { code: 138, productName: 'Roll Cap', content: '10 Roll', actualRate: '₹ 500', price: 75 },
    { code: 139, productName: '7 Up Rainbow', content: '100 Pcs', actualRate: '₹ 1135', price: 170 },
    { code: 140, productName: 'Robin Laptop 10 in 1', content: '100 Pcs', actualRate: '₹ 1600', price: 240 },
]


export const newArrival = [
    { code: 141, productName: 'Magic Paper Bomb', content: '1 Pcs', actualRate: '₹ 535', price: 80 },
    { code: 142, productName: 'Magic Currency Bomb', content: '2 Pcs', actualRate: '₹ 1668', price: 250 },
    { code: 143, productName: 'Drone / Helicopter (5 Pcs)', content: '5 Pcs', actualRate: '₹ 1000', price: 150 },
    { code: 144, productName: 'Pistal 5G', content: '2 Pcs', actualRate: '₹ 1334', price: 200 },
    { code: 145, productName: 'Rainbow Smoke', content: '2 Pcs', actualRate: '₹ 800', price: 120 },
    { code: 146, productName: 'Mozilla Full Crackling', content: '3 Pcs', actualRate: '₹ 2000', price: 300 },
    { code: 147, productName: 'Water Queen', content: '1 Pcs', actualRate: '₹ 1168', price: 175 },
    { code: 148, productName: 'Flower Wheel', content: '2 Pcs', actualRate: '₹ 1334', price: 200 },
    // { code: 152, productName: 'OLD IS GOLD', content: '1 Box', actualRate: '₹ 1140', price: 170 },
    { code: 149, productName: '4X4 WHEEL', content: '1 Box', actualRate: '₹ 1135', price: 170 },
    // { code: 150, productName: 'LOLLIPOP (5 Pcs)', content: '1 Box', actualRate: '₹ 1668', price: 250 },
    { code: 150, productName: 'Black Money (5 pcs) Currency', content: '5 Pcs', actualRate: '₹ 1334', price: 200 },
    // { code: 156, productName: 'Fire Egg (Vadivel) ', content: '2 Pcs', actualRate: '₹ 1334', price: 200 },
]


export const ladduSpecialFountain = [
    { code: 151, productName: 'Mini Jewels (5 pcs)', content: '5 Pcs', actualRate: '₹ 1000', price: 150 },
    { code: 152, productName: 'Rim Jhim (5 pcs)', content: '5 Pcs', actualRate: '₹ 1334', price: 200 },
    { code: 153, productName: 'Gift Pack Big (4 Pcs)', content: '10 Pcs', actualRate: '₹ 4000', price: 600 },
]


export const ladduSpecialFountain1 = [
    { code: 154, productName: 'Mini Jewels (5 pcs)', content: '5 Pcs', actualRate: '₹ 1000', price: 150 },
    { code: 155, productName: 'Rim Jhim (5 pcs)', content: '5 Pcs', actualRate: '₹ 1334', price: 200 },
    { code: 156, productName: 'Gift Pack Big1 (4 Pcs)', content: '10 Pcs', actualRate: '₹ 4000', price: 600 },
    { code: 157, productName: 'Gift Pack Big2 (4 Pcs)', content: '10 Pcs', actualRate: '₹ 4000', price: 600 },
    { code: 158, productName: 'Gift Pack Big3 (4 Pcs)', content: '10 Pcs', actualRate: '₹ 4000', price: 600 },
    { code: 159, productName: 'Gift Pack Big4 (4 Pcs)', content: '10 Pcs', actualRate: '₹ 4000', price: 600 },
]

export const festival = [
    { code: 160, productName: '1000 Wala', content: '1 Pcs', actualRate: '₹ 1700', price: 255 },
    // { code: 14, productName: '300 Wala', content: '1 Pcs', actualRate: '₹ 794', price: 119 },
    // { code: 15, productName: '600 Wala', content: '1 Pcs', actualRate: '₹ 1088', price: 163 },
    // { code: 16, productName: '1000 Wala Full', content: '1 Pcs', actualRate: '₹ 1500', price: 225 },
    { code: 161, productName: '2000 Wala Full', content: '1 Pcs', actualRate: '₹ 3400', price: 510 },
    { code: 162, productName: '5000 Wala Full', content: '1 Pcs', actualRate: '₹ 9500', price: 1425 },
    { code: 163, productName: '10000 Wala Full', content: '1 Pcs', actualRate: '₹ 17000', price: 2550 },
]
export const newArrivalAnaconda = [
    { code: 154, productName: 'Candy Crush (ollai vedi)', content: '25 Pcs', actualRate: '₹ 1134', price: 170 },
    { code: 155, productName: 'Master Blaster (Bat)', content: '1 Bat', actualRate: '₹ 1134', price: 170 },    
    { code: 156, productName: 'Po Po (Silver Fountain)', content: '5 Pcs', actualRate: '₹ 1600', price: 240 },
    { code: 157, productName: 'Mojo (Glittering Fountain)', content: '5 Pcs', actualRate: '₹ 1600', price: 240 },
    { code: 158, productName: 'Smily Stick (Lollipop)', content: '5 Pcs', actualRate: '₹ 1600', price: 240 },
    { code: 159, productName: 'Go Go (Bus) Crackling Fountain', content: '5 Pcs', actualRate: '₹ 1668', price: 285 },
]

export const electric = [
    { code: 7, productName: 'Devotional Gift Pack', content: '21 Items', actualRate: '₹ 350', price: 250 },
    { code: 8, productName: 'Family Pack', content: '25 Items', actualRate: '₹ 547', price: 320 },
    { code: 9, productName: 'Gold Gift Box', content: '31 Items', actualRate: '₹ 620', price: 420 },
    { code: 10, productName: 'Festival Family Pack', content: '42 Items', actualRate: '₹ 1110', price: 700 },
];
export const avpremiumgiftboxes  = [
    { code: 164, productName: 'Devotional Gift Pack ' ,content:'21 Items',actualRate: '0',price: 250 },
    { code: 165, productName: 'Family Pack ',content:'25 Items',actualRate: '0',price: 320 }, 
    { code: 166, productName: 'Gold Gift Box ',content:'31 Items',actualRate: '0',price: 420 },
    { code: 167, productName: 'Festival Family Pack ',content:'42 Items',actualRate: '0',price: 700 }, 
    
];
//     { code: 11, productName: '50 Deluxe', content: '1 Pkt', actualRate: '₹ 680', price: 102 },
//     { code: 12, productName: '100 Deluxe', content: '1 Pkt', actualRate: '₹ 1170', price: 175 },
// ];





// export const weddingSeries = [
//     { code: 51, productName: 'Wedding Red', content: '5 Pcs', actualRate: '₹ 868', price: 130 },
//     { code: 52, productName: 'Red Flash', content: '5 Pcs', actualRate: '₹ 668', price: 100 },
//     { code: 53, productName: 'Lolly Pop', content: '5 Pcs', actualRate: '₹ 668', price: 100 },
// ];















